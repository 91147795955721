import '../scss/main.scss'

import './jquery';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

import "jquery.scrollto";
import "jquery.localscroll";
import "jquery-validation";

$(document).ready(function() {
  $.localScroll();

  $('#contact-form').validate({
    errorClass: 'text-danger',
    submitHandler: function(form) {

      // console.log(grecaptcha.getResponse());

      $(form).ajaxSubmit({
        resetForm: true,
        success: function(response) {
          var message = '<div class="contact-form-alert alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>' + response + '</div>';
          $('#contact-form').prepend(message);
        }
      });
    }
  });

});
